export const mapToken = {
  borderRadiusLG: 8,
  borderRadiusSM: 4,
  borderRadiusXS: 2,

  colorBgContainer: '#ffffff',
  colorBgElevated: '#ffffff',
  colorBgLayout: '#ffffff',
  colorBgMask: 'rgba(0, 0, 0, 0.45)',
  colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
  colorBorder: '#d9d9d9',
  colorBorderSecondary: '#f0f0f0',
  colorErrorActive: '#d9363e',
  colorErrorBg: '#fff2f0',
  colorErrorBgHover: '#fff1f0',
  colorErrorBorder: '#ffccc7',
  colorErrorBorderHover: '#ffa39e',
  colorErrorHover: '#ff7875',
  colorErrorText: '#ff4d4f',
  colorErrorTextActive: '#d9363e',
  colorErrorTextHover: '#ff7875',
  colorFill: 'rgba(0, 0, 0, 0.15)',
  colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
  colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
  colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
  colorInfoActive: '#0958d9',
  colorInfoBg: '#e6f4ff',
  colorInfoBgHover: '#bae0ff',
  colorInfoBorder: '#91caff',
  colorInfoBorderHover: '#69b1ff',
  colorInfoHover: '#69b1ff',
  colorInfoText: '#1677ff',
  colorInfoTextActive: '#0958d9',
  colorInfoTextHover: '#4096ff',
  colorPrimaryActive: '#0958d9',
  colorPrimaryBg: '#e6f4ff',
  colorPrimaryBgHover: '#bae0ff',
  colorPrimaryBorder: '#91caff',
  colorPrimaryBorderHover: '#69b1ff',
  colorPrimaryHover: '#4096ff',
  colorPrimaryText: '#1677ff',
  colorPrimaryTextActive: '#0958d9',
  colorPrimaryTextHover: '#4096ff',
  colorSuccessActive: '#389e0d',
  colorSuccessBg: '#f6ffed',
  colorSuccessBgHover: '#d9f7be',
  colorSuccessBorder: '#b7eb8f',
  colorSuccessBorderHover: '#95de64',
  colorSuccessHover: '#95de64',
  colorSuccessText: '#52c41a',
  colorSuccessTextActive: '#389e0d',
  colorSuccessTextHover: '#73d13d',
  colorText: 'rgba(0, 0, 0, 0.88)',
  colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
  colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
  colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
  colorWarningActive: '#d48806',
  colorWarningBg: '#fffbe6',
  colorWarningBgHover: '#fff1b8',
  colorWarningBorder: '#ffe58f',
  colorWarningBorderHover: '#ffd666',
  colorWarningHover: '#ffd666',
  colorWarningText: '#faad14',
  colorWarningTextActive: '#d48806',
  colorWarningTextHover: '#ffc53d',
  colorWhite: '#ffffff',

  fontSizeHeading1: 38,
  fontSizeHeading2: 30,
  fontSizeHeading3: 24,
  fontSizeHeading4: 20,
  fontSizeHeading5: 16,
  fontSizeLG: 16,
  fontSizeSM: 12,
  fontSizeXL: 20,

  lineHeight: 121 / 77,
  lineHeightHeading1: 46 / 38,
  lineHeightHeading2: 3.8 / 3,
  lineHeightHeading3: 4 / 3,
  lineHeightHeading4: 1.4,
  lineHeightHeading5: 1.5,
  lineHeightLG: 1.5,
  lineHeightSM: 5 / 3,
  lineWidthBold: 2,

  motionDurationFast: '0.1s',
  motionDurationMid: '0.2s',
  motionDurationSlow: '0.3s',

  sizeXXS: 4,
  sizeXS: 8,
  sizeSM: 12,
  size: 16,
  sizeMS: 16,
  sizeMD: 20,
  sizeLG: 24,
  sizeXL: 32,
  sizeXXL: 48,
};
