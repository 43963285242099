// source: event/web_partnership/seat_map.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
goog.exportSymbol('proto.event.web_partnership.BlockStats', null, global);
goog.exportSymbol('proto.event.web_partnership.CreateSeatBlockReq', null, global);
goog.exportSymbol('proto.event.web_partnership.CreateSeatBlockRes', null, global);
goog.exportSymbol('proto.event.web_partnership.CreateSeatMapReq', null, global);
goog.exportSymbol('proto.event.web_partnership.CreateSeatMapRes', null, global);
goog.exportSymbol('proto.event.web_partnership.Currency', null, global);
goog.exportSymbol('proto.event.web_partnership.DeleteSeatBlockReq', null, global);
goog.exportSymbol('proto.event.web_partnership.DeleteSeatMapReq', null, global);
goog.exportSymbol('proto.event.web_partnership.EventAssocStatus', null, global);
goog.exportSymbol('proto.event.web_partnership.ListDecorationReq', null, global);
goog.exportSymbol('proto.event.web_partnership.ListDecorationRes', null, global);
goog.exportSymbol('proto.event.web_partnership.ListSeatBlockReq', null, global);
goog.exportSymbol('proto.event.web_partnership.ListSeatBlockRes', null, global);
goog.exportSymbol('proto.event.web_partnership.ListSeatMapReq', null, global);
goog.exportSymbol('proto.event.web_partnership.ListSeatMapRes', null, global);
goog.exportSymbol('proto.event.web_partnership.Position', null, global);
goog.exportSymbol('proto.event.web_partnership.RetrieveSeatBlockReq', null, global);
goog.exportSymbol('proto.event.web_partnership.RetrieveSeatBlockRes', null, global);
goog.exportSymbol('proto.event.web_partnership.RetrieveSeatMapReq', null, global);
goog.exportSymbol('proto.event.web_partnership.RetrieveSeatMapRes', null, global);
goog.exportSymbol('proto.event.web_partnership.Seat', null, global);
goog.exportSymbol('proto.event.web_partnership.SeatBlock', null, global);
goog.exportSymbol('proto.event.web_partnership.SeatBlockInfo', null, global);
goog.exportSymbol('proto.event.web_partnership.SeatLine', null, global);
goog.exportSymbol('proto.event.web_partnership.SeatMap', null, global);
goog.exportSymbol('proto.event.web_partnership.SeatMapInfo', null, global);
goog.exportSymbol('proto.event.web_partnership.SeatStatus', null, global);
goog.exportSymbol('proto.event.web_partnership.SeatStatusLog', null, global);
goog.exportSymbol('proto.event.web_partnership.Sharp', null, global);
goog.exportSymbol('proto.event.web_partnership.UpdateSeatBlockReq', null, global);
goog.exportSymbol('proto.event.web_partnership.UpdateSeatMapReq', null, global);
goog.exportSymbol('proto.event.web_partnership.UpdateSeatStatusDetailReq', null, global);
goog.exportSymbol('proto.event.web_partnership.UpdateSeatStatusReq', null, global);
goog.exportSymbol('proto.event.web_partnership.UpdateSeatStatusRes', null, global);
goog.exportSymbol('proto.event.web_partnership.UpsertDecorationReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.SeatMapInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.SeatMapInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.SeatMapInfo.displayName = 'proto.event.web_partnership.SeatMapInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.SeatMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.SeatMap.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.SeatMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.SeatMap.displayName = 'proto.event.web_partnership.SeatMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.SeatBlockInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.SeatBlockInfo.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.SeatBlockInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.SeatBlockInfo.displayName = 'proto.event.web_partnership.SeatBlockInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.SeatBlock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.SeatBlock.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.SeatBlock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.SeatBlock.displayName = 'proto.event.web_partnership.SeatBlock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.Sharp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.Sharp.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.Sharp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.Sharp.displayName = 'proto.event.web_partnership.Sharp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.BlockStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.BlockStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.BlockStats.displayName = 'proto.event.web_partnership.BlockStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.SeatLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.SeatLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.SeatLine.displayName = 'proto.event.web_partnership.SeatLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.Currency.displayName = 'proto.event.web_partnership.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.Position.displayName = 'proto.event.web_partnership.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.Seat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.Seat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.Seat.displayName = 'proto.event.web_partnership.Seat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.SeatStatusLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.SeatStatusLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.SeatStatusLog.displayName = 'proto.event.web_partnership.SeatStatusLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.CreateSeatMapReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.CreateSeatMapReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.CreateSeatMapReq.displayName = 'proto.event.web_partnership.CreateSeatMapReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.UpdateSeatMapReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.UpdateSeatMapReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.UpdateSeatMapReq.displayName = 'proto.event.web_partnership.UpdateSeatMapReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.DeleteSeatMapReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.DeleteSeatMapReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.DeleteSeatMapReq.displayName = 'proto.event.web_partnership.DeleteSeatMapReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.ListSeatMapReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.ListSeatMapReq.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.ListSeatMapReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.ListSeatMapReq.displayName = 'proto.event.web_partnership.ListSeatMapReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.RetrieveSeatMapReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.RetrieveSeatMapReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.RetrieveSeatMapReq.displayName = 'proto.event.web_partnership.RetrieveSeatMapReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.CreateSeatBlockReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.CreateSeatBlockReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.CreateSeatBlockReq.displayName = 'proto.event.web_partnership.CreateSeatBlockReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.ListSeatBlockReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.ListSeatBlockReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.ListSeatBlockReq.displayName = 'proto.event.web_partnership.ListSeatBlockReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.UpdateSeatBlockReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.UpdateSeatBlockReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.UpdateSeatBlockReq.displayName = 'proto.event.web_partnership.UpdateSeatBlockReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.DeleteSeatBlockReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.DeleteSeatBlockReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.DeleteSeatBlockReq.displayName = 'proto.event.web_partnership.DeleteSeatBlockReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.RetrieveSeatBlockReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.RetrieveSeatBlockReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.RetrieveSeatBlockReq.displayName = 'proto.event.web_partnership.RetrieveSeatBlockReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.UpsertDecorationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.UpsertDecorationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.UpsertDecorationReq.displayName = 'proto.event.web_partnership.UpsertDecorationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.UpdateSeatStatusReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.UpdateSeatStatusReq.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.UpdateSeatStatusReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.UpdateSeatStatusReq.displayName = 'proto.event.web_partnership.UpdateSeatStatusReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.UpdateSeatStatusDetailReq.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.UpdateSeatStatusDetailReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.UpdateSeatStatusDetailReq.displayName = 'proto.event.web_partnership.UpdateSeatStatusDetailReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.ListDecorationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.ListDecorationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.ListDecorationReq.displayName = 'proto.event.web_partnership.ListDecorationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.CreateSeatMapRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.CreateSeatMapRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.CreateSeatMapRes.displayName = 'proto.event.web_partnership.CreateSeatMapRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.RetrieveSeatMapRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.RetrieveSeatMapRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.RetrieveSeatMapRes.displayName = 'proto.event.web_partnership.RetrieveSeatMapRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.ListSeatMapRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.ListSeatMapRes.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.ListSeatMapRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.ListSeatMapRes.displayName = 'proto.event.web_partnership.ListSeatMapRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.ListSeatBlockRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.ListSeatBlockRes.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.ListSeatBlockRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.ListSeatBlockRes.displayName = 'proto.event.web_partnership.ListSeatBlockRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.ListDecorationRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.ListDecorationRes.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.ListDecorationRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.ListDecorationRes.displayName = 'proto.event.web_partnership.ListDecorationRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.CreateSeatBlockRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.CreateSeatBlockRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.CreateSeatBlockRes.displayName = 'proto.event.web_partnership.CreateSeatBlockRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.UpdateSeatStatusRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event.web_partnership.UpdateSeatStatusRes.repeatedFields_, null);
};
goog.inherits(proto.event.web_partnership.UpdateSeatStatusRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.UpdateSeatStatusRes.displayName = 'proto.event.web_partnership.UpdateSeatStatusRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event.web_partnership.RetrieveSeatBlockRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event.web_partnership.RetrieveSeatBlockRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event.web_partnership.RetrieveSeatBlockRes.displayName = 'proto.event.web_partnership.RetrieveSeatBlockRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.SeatMapInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.SeatMapInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.SeatMapInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatMapInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mapPicture: jspb.Message.getFieldWithDefault(msg, 4, ""),
    maxSeatPerOrder: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.SeatMapInfo}
 */
proto.event.web_partnership.SeatMapInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.SeatMapInfo;
  return proto.event.web_partnership.SeatMapInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.SeatMapInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.SeatMapInfo}
 */
proto.event.web_partnership.SeatMapInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapPicture(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxSeatPerOrder(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.SeatMapInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.SeatMapInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.SeatMapInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatMapInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMapPicture();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMaxSeatPerOrder();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.event.web_partnership.SeatMapInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMapInfo} returns this
 */
proto.event.web_partnership.SeatMapInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.event.web_partnership.SeatMapInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMapInfo} returns this
 */
proto.event.web_partnership.SeatMapInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string map_picture = 4;
 * @return {string}
 */
proto.event.web_partnership.SeatMapInfo.prototype.getMapPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMapInfo} returns this
 */
proto.event.web_partnership.SeatMapInfo.prototype.setMapPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 max_seat_per_order = 5;
 * @return {number}
 */
proto.event.web_partnership.SeatMapInfo.prototype.getMaxSeatPerOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMapInfo} returns this
 */
proto.event.web_partnership.SeatMapInfo.prototype.setMaxSeatPerOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.event.web_partnership.SeatMapInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMapInfo} returns this
 */
proto.event.web_partnership.SeatMapInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.SeatMap.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.SeatMap.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.SeatMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.SeatMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mapPicture: jspb.Message.getFieldWithDefault(msg, 4, ""),
    maxSeatPerOrder: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    countBlock: jspb.Message.getFieldWithDefault(msg, 11, 0),
    countSeat: jspb.Message.getFieldWithDefault(msg, 12, 0),
    minPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    maxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    decorationItemsList: jspb.Message.toObjectList(msg.getDecorationItemsList(),
    proto.event.web_partnership.Sharp.toObject, includeInstance),
    eventId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    scheduleId: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.SeatMap}
 */
proto.event.web_partnership.SeatMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.SeatMap;
  return proto.event.web_partnership.SeatMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.SeatMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.SeatMap}
 */
proto.event.web_partnership.SeatMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapPicture(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxSeatPerOrder(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountBlock(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountSeat(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinPrice(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxPrice(value);
      break;
    case 15:
      var value = new proto.event.web_partnership.Sharp;
      reader.readMessage(value,proto.event.web_partnership.Sharp.deserializeBinaryFromReader);
      msg.addDecorationItems(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.SeatMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.SeatMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.SeatMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMapPicture();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMaxSeatPerOrder();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCountBlock();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCountSeat();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getMinPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getDecorationItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.event.web_partnership.Sharp.serializeBinaryToWriter
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getScheduleId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string map_picture = 4;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getMapPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setMapPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 max_seat_per_order = 5;
 * @return {number}
 */
proto.event.web_partnership.SeatMap.prototype.getMaxSeatPerOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setMaxSeatPerOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.event.web_partnership.SeatMap.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string created_by = 8;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.event.web_partnership.SeatMap.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string updated_by = 10;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 count_block = 11;
 * @return {number}
 */
proto.event.web_partnership.SeatMap.prototype.getCountBlock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setCountBlock = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 count_seat = 12;
 * @return {number}
 */
proto.event.web_partnership.SeatMap.prototype.getCountSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setCountSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional double min_price = 13;
 * @return {number}
 */
proto.event.web_partnership.SeatMap.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double max_price = 14;
 * @return {number}
 */
proto.event.web_partnership.SeatMap.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setMaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * repeated Sharp decoration_items = 15;
 * @return {!Array<!proto.event.web_partnership.Sharp>}
 */
proto.event.web_partnership.SeatMap.prototype.getDecorationItemsList = function() {
  return /** @type{!Array<!proto.event.web_partnership.Sharp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.Sharp, 15));
};


/**
 * @param {!Array<!proto.event.web_partnership.Sharp>} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
*/
proto.event.web_partnership.SeatMap.prototype.setDecorationItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.event.web_partnership.Sharp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.Sharp}
 */
proto.event.web_partnership.SeatMap.prototype.addDecorationItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.event.web_partnership.Sharp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.clearDecorationItemsList = function() {
  return this.setDecorationItemsList([]);
};


/**
 * optional string event_id = 16;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string schedule_id = 17;
 * @return {string}
 */
proto.event.web_partnership.SeatMap.prototype.getScheduleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatMap} returns this
 */
proto.event.web_partnership.SeatMap.prototype.setScheduleId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.SeatBlockInfo.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.SeatBlockInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.SeatBlockInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatBlockInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    row: (f = msg.getRow()) && proto.event.web_partnership.SeatLine.toObject(includeInstance, f),
    col: (f = msg.getCol()) && proto.event.web_partnership.SeatLine.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalSeat: jspb.Message.getFieldWithDefault(msg, 7, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    position: (f = msg.getPosition()) && proto.event.web_partnership.Position.toObject(includeInstance, f),
    seatMapId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    canSelectSeat: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.event.web_partnership.Seat.toObject, includeInstance),
    rotation: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    paperReserved: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.SeatBlockInfo}
 */
proto.event.web_partnership.SeatBlockInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.SeatBlockInfo;
  return proto.event.web_partnership.SeatBlockInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.SeatBlockInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.SeatBlockInfo}
 */
proto.event.web_partnership.SeatBlockInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = new proto.event.web_partnership.SeatLine;
      reader.readMessage(value,proto.event.web_partnership.SeatLine.deserializeBinaryFromReader);
      msg.setRow(value);
      break;
    case 4:
      var value = new proto.event.web_partnership.SeatLine;
      reader.readMessage(value,proto.event.web_partnership.SeatLine.deserializeBinaryFromReader);
      msg.setCol(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeight(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalSeat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 9:
      var value = new proto.event.web_partnership.Position;
      reader.readMessage(value,proto.event.web_partnership.Position.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanSelectSeat(value);
      break;
    case 12:
      var value = new proto.event.web_partnership.Seat;
      reader.readMessage(value,proto.event.web_partnership.Seat.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRotation(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaperReserved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.SeatBlockInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.SeatBlockInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatBlockInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRow();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.event.web_partnership.SeatLine.serializeBinaryToWriter
    );
  }
  f = message.getCol();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.event.web_partnership.SeatLine.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalSeat();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.event.web_partnership.Position.serializeBinaryToWriter
    );
  }
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCanSelectSeat();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.event.web_partnership.Seat.serializeBinaryToWriter
    );
  }
  f = message.getRotation();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getPaperReserved();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SeatLine row = 3;
 * @return {?proto.event.web_partnership.SeatLine}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getRow = function() {
  return /** @type{?proto.event.web_partnership.SeatLine} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatLine, 3));
};


/**
 * @param {?proto.event.web_partnership.SeatLine|undefined} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
*/
proto.event.web_partnership.SeatBlockInfo.prototype.setRow = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.clearRow = function() {
  return this.setRow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.hasRow = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SeatLine col = 4;
 * @return {?proto.event.web_partnership.SeatLine}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getCol = function() {
  return /** @type{?proto.event.web_partnership.SeatLine} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatLine, 4));
};


/**
 * @param {?proto.event.web_partnership.SeatLine|undefined} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
*/
proto.event.web_partnership.SeatBlockInfo.prototype.setCol = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.clearCol = function() {
  return this.setCol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.hasCol = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double width = 5;
 * @return {number}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double height = 6;
 * @return {number}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 total_seat = 7;
 * @return {number}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getTotalSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setTotalSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double price = 8;
 * @return {number}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional Position position = 9;
 * @return {?proto.event.web_partnership.Position}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getPosition = function() {
  return /** @type{?proto.event.web_partnership.Position} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.Position, 9));
};


/**
 * @param {?proto.event.web_partnership.Position|undefined} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
*/
proto.event.web_partnership.SeatBlockInfo.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string seat_map_id = 10;
 * @return {string}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool can_select_seat = 11;
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getCanSelectSeat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setCanSelectSeat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated Seat seats = 12;
 * @return {!Array<!proto.event.web_partnership.Seat>}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.event.web_partnership.Seat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.Seat, 12));
};


/**
 * @param {!Array<!proto.event.web_partnership.Seat>} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
*/
proto.event.web_partnership.SeatBlockInfo.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.event.web_partnership.Seat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.Seat}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.event.web_partnership.Seat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * optional double rotation = 13;
 * @return {number}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getRotation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setRotation = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int32 paper_reserved = 14;
 * @return {number}
 */
proto.event.web_partnership.SeatBlockInfo.prototype.getPaperReserved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlockInfo} returns this
 */
proto.event.web_partnership.SeatBlockInfo.prototype.setPaperReserved = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.SeatBlock.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.SeatBlock.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.SeatBlock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.SeatBlock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatBlock.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, ""),
    row: (f = msg.getRow()) && proto.event.web_partnership.SeatLine.toObject(includeInstance, f),
    col: (f = msg.getCol()) && proto.event.web_partnership.SeatLine.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalSeat: jspb.Message.getFieldWithDefault(msg, 8, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    position: (f = msg.getPosition()) && proto.event.web_partnership.Position.toObject(includeInstance, f),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.event.web_partnership.Seat.toObject, includeInstance),
    seatMapId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    canSelectSeat: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 17, ""),
    rotation: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    paperReserved: jspb.Message.getFieldWithDefault(msg, 19, 0),
    stats: (f = msg.getStats()) && proto.event.web_partnership.BlockStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.SeatBlock}
 */
proto.event.web_partnership.SeatBlock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.SeatBlock;
  return proto.event.web_partnership.SeatBlock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.SeatBlock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.SeatBlock}
 */
proto.event.web_partnership.SeatBlock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 4:
      var value = new proto.event.web_partnership.SeatLine;
      reader.readMessage(value,proto.event.web_partnership.SeatLine.deserializeBinaryFromReader);
      msg.setRow(value);
      break;
    case 5:
      var value = new proto.event.web_partnership.SeatLine;
      reader.readMessage(value,proto.event.web_partnership.SeatLine.deserializeBinaryFromReader);
      msg.setCol(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeight(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalSeat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 10:
      var value = new proto.event.web_partnership.Position;
      reader.readMessage(value,proto.event.web_partnership.Position.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 11:
      var value = new proto.event.web_partnership.Seat;
      reader.readMessage(value,proto.event.web_partnership.Seat.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanSelectSeat(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRotation(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaperReserved(value);
      break;
    case 20:
      var value = new proto.event.web_partnership.BlockStats;
      reader.readMessage(value,proto.event.web_partnership.BlockStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.SeatBlock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.SeatBlock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.SeatBlock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatBlock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRow();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.event.web_partnership.SeatLine.serializeBinaryToWriter
    );
  }
  f = message.getCol();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.event.web_partnership.SeatLine.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalSeat();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.event.web_partnership.Position.serializeBinaryToWriter
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.event.web_partnership.Seat.serializeBinaryToWriter
    );
  }
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCanSelectSeat();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getRotation();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getPaperReserved();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.event.web_partnership.BlockStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.SeatBlock.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.event.web_partnership.SeatBlock.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.event.web_partnership.SeatBlock.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SeatLine row = 4;
 * @return {?proto.event.web_partnership.SeatLine}
 */
proto.event.web_partnership.SeatBlock.prototype.getRow = function() {
  return /** @type{?proto.event.web_partnership.SeatLine} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatLine, 4));
};


/**
 * @param {?proto.event.web_partnership.SeatLine|undefined} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
*/
proto.event.web_partnership.SeatBlock.prototype.setRow = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.clearRow = function() {
  return this.setRow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlock.prototype.hasRow = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SeatLine col = 5;
 * @return {?proto.event.web_partnership.SeatLine}
 */
proto.event.web_partnership.SeatBlock.prototype.getCol = function() {
  return /** @type{?proto.event.web_partnership.SeatLine} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatLine, 5));
};


/**
 * @param {?proto.event.web_partnership.SeatLine|undefined} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
*/
proto.event.web_partnership.SeatBlock.prototype.setCol = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.clearCol = function() {
  return this.setCol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlock.prototype.hasCol = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double width = 6;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double height = 7;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 total_seat = 8;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getTotalSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setTotalSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double price = 9;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional Position position = 10;
 * @return {?proto.event.web_partnership.Position}
 */
proto.event.web_partnership.SeatBlock.prototype.getPosition = function() {
  return /** @type{?proto.event.web_partnership.Position} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.Position, 10));
};


/**
 * @param {?proto.event.web_partnership.Position|undefined} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
*/
proto.event.web_partnership.SeatBlock.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlock.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated Seat seats = 11;
 * @return {!Array<!proto.event.web_partnership.Seat>}
 */
proto.event.web_partnership.SeatBlock.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.event.web_partnership.Seat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.Seat, 11));
};


/**
 * @param {!Array<!proto.event.web_partnership.Seat>} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
*/
proto.event.web_partnership.SeatBlock.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.event.web_partnership.Seat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.Seat}
 */
proto.event.web_partnership.SeatBlock.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.event.web_partnership.Seat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * optional string seat_map_id = 12;
 * @return {string}
 */
proto.event.web_partnership.SeatBlock.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool can_select_seat = 13;
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlock.prototype.getCanSelectSeat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setCanSelectSeat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 created_at = 14;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string created_by = 15;
 * @return {string}
 */
proto.event.web_partnership.SeatBlock.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 updated_at = 16;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string updated_by = 17;
 * @return {string}
 */
proto.event.web_partnership.SeatBlock.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional double rotation = 18;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getRotation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setRotation = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional int32 paper_reserved = 19;
 * @return {number}
 */
proto.event.web_partnership.SeatBlock.prototype.getPaperReserved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.setPaperReserved = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional BlockStats stats = 20;
 * @return {?proto.event.web_partnership.BlockStats}
 */
proto.event.web_partnership.SeatBlock.prototype.getStats = function() {
  return /** @type{?proto.event.web_partnership.BlockStats} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.BlockStats, 20));
};


/**
 * @param {?proto.event.web_partnership.BlockStats|undefined} value
 * @return {!proto.event.web_partnership.SeatBlock} returns this
*/
proto.event.web_partnership.SeatBlock.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.SeatBlock} returns this
 */
proto.event.web_partnership.SeatBlock.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.SeatBlock.prototype.hasStats = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.Sharp.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.Sharp.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.Sharp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.Sharp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Sharp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, ""),
    position: (f = msg.getPosition()) && proto.event.web_partnership.Position.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.event.web_partnership.Position.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.Sharp}
 */
proto.event.web_partnership.Sharp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.Sharp;
  return proto.event.web_partnership.Sharp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.Sharp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.Sharp}
 */
proto.event.web_partnership.Sharp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 4:
      var value = new proto.event.web_partnership.Position;
      reader.readMessage(value,proto.event.web_partnership.Position.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = new proto.event.web_partnership.Position;
      reader.readMessage(value,proto.event.web_partnership.Position.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.Sharp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.Sharp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.Sharp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Sharp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.event.web_partnership.Position.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.event.web_partnership.Position.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.Sharp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Sharp} returns this
 */
proto.event.web_partnership.Sharp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.event.web_partnership.Sharp.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Sharp} returns this
 */
proto.event.web_partnership.Sharp.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.event.web_partnership.Sharp.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Sharp} returns this
 */
proto.event.web_partnership.Sharp.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Position position = 4;
 * @return {?proto.event.web_partnership.Position}
 */
proto.event.web_partnership.Sharp.prototype.getPosition = function() {
  return /** @type{?proto.event.web_partnership.Position} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.Position, 4));
};


/**
 * @param {?proto.event.web_partnership.Position|undefined} value
 * @return {!proto.event.web_partnership.Sharp} returns this
*/
proto.event.web_partnership.Sharp.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.Sharp} returns this
 */
proto.event.web_partnership.Sharp.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.Sharp.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.event.web_partnership.Sharp.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Sharp} returns this
 */
proto.event.web_partnership.Sharp.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Position points = 6;
 * @return {!Array<!proto.event.web_partnership.Position>}
 */
proto.event.web_partnership.Sharp.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.event.web_partnership.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.Position, 6));
};


/**
 * @param {!Array<!proto.event.web_partnership.Position>} value
 * @return {!proto.event.web_partnership.Sharp} returns this
*/
proto.event.web_partnership.Sharp.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.event.web_partnership.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.Position}
 */
proto.event.web_partnership.Sharp.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.event.web_partnership.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.Sharp} returns this
 */
proto.event.web_partnership.Sharp.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.BlockStats.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.BlockStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.BlockStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.BlockStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    available: jspb.Message.getFieldWithDefault(msg, 1, 0),
    booking: jspb.Message.getFieldWithDefault(msg, 2, 0),
    locked: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sold: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.BlockStats}
 */
proto.event.web_partnership.BlockStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.BlockStats;
  return proto.event.web_partnership.BlockStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.BlockStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.BlockStats}
 */
proto.event.web_partnership.BlockStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvailable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBooking(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLocked(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.BlockStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.BlockStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.BlockStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.BlockStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailable();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBooking();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLocked();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSold();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 available = 1;
 * @return {number}
 */
proto.event.web_partnership.BlockStats.prototype.getAvailable = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.BlockStats} returns this
 */
proto.event.web_partnership.BlockStats.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 booking = 2;
 * @return {number}
 */
proto.event.web_partnership.BlockStats.prototype.getBooking = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.BlockStats} returns this
 */
proto.event.web_partnership.BlockStats.prototype.setBooking = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 locked = 3;
 * @return {number}
 */
proto.event.web_partnership.BlockStats.prototype.getLocked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.BlockStats} returns this
 */
proto.event.web_partnership.BlockStats.prototype.setLocked = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 sold = 4;
 * @return {number}
 */
proto.event.web_partnership.BlockStats.prototype.getSold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.BlockStats} returns this
 */
proto.event.web_partnership.BlockStats.prototype.setSold = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.SeatLine.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.SeatLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.SeatLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    quantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    beginCharacter: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.SeatLine}
 */
proto.event.web_partnership.SeatLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.SeatLine;
  return proto.event.web_partnership.SeatLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.SeatLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.SeatLine}
 */
proto.event.web_partnership.SeatLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginCharacter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.SeatLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.SeatLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.SeatLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBeginCharacter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 quantity = 1;
 * @return {number}
 */
proto.event.web_partnership.SeatLine.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatLine} returns this
 */
proto.event.web_partnership.SeatLine.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string begin_character = 2;
 * @return {string}
 */
proto.event.web_partnership.SeatLine.prototype.getBeginCharacter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatLine} returns this
 */
proto.event.web_partnership.SeatLine.prototype.setBeginCharacter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.Currency}
 */
proto.event.web_partnership.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.Currency;
  return proto.event.web_partnership.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.Currency}
 */
proto.event.web_partnership.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.Currency.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Currency} returns this
 */
proto.event.web_partnership.Currency.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.event.web_partnership.Currency.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Currency} returns this
 */
proto.event.web_partnership.Currency.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.event.web_partnership.Currency.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Currency} returns this
 */
proto.event.web_partnership.Currency.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string icon = 4;
 * @return {string}
 */
proto.event.web_partnership.Currency.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Currency} returns this
 */
proto.event.web_partnership.Currency.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.Position}
 */
proto.event.web_partnership.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.Position;
  return proto.event.web_partnership.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.Position}
 */
proto.event.web_partnership.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.event.web_partnership.Position.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.Position} returns this
 */
proto.event.web_partnership.Position.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.event.web_partnership.Position.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.Position} returns this
 */
proto.event.web_partnership.Position.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.Seat.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.Seat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.Seat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Seat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    position: (f = msg.getPosition()) && proto.event.web_partnership.Position.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderX: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orderY: jspb.Message.getFieldWithDefault(msg, 6, 0),
    log: (f = msg.getLog()) && proto.event.web_partnership.SeatStatusLog.toObject(includeInstance, f),
    isPaperTicket: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    ownerId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.Seat}
 */
proto.event.web_partnership.Seat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.Seat;
  return proto.event.web_partnership.Seat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.Seat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.Seat}
 */
proto.event.web_partnership.Seat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.event.web_partnership.Position;
      reader.readMessage(value,proto.event.web_partnership.Position.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = /** @type {!proto.event.web_partnership.SeatStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderX(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderY(value);
      break;
    case 7:
      var value = new proto.event.web_partnership.SeatStatusLog;
      reader.readMessage(value,proto.event.web_partnership.SeatStatusLog.deserializeBinaryFromReader);
      msg.setLog(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPaperTicket(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.Seat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.Seat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.Seat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.Seat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.event.web_partnership.Position.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOrderX();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOrderY();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLog();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.event.web_partnership.SeatStatusLog.serializeBinaryToWriter
    );
  }
  f = message.getIsPaperTicket();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.Seat.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.event.web_partnership.Seat.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Position position = 3;
 * @return {?proto.event.web_partnership.Position}
 */
proto.event.web_partnership.Seat.prototype.getPosition = function() {
  return /** @type{?proto.event.web_partnership.Position} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.Position, 3));
};


/**
 * @param {?proto.event.web_partnership.Position|undefined} value
 * @return {!proto.event.web_partnership.Seat} returns this
*/
proto.event.web_partnership.Seat.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.Seat.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SeatStatus status = 4;
 * @return {!proto.event.web_partnership.SeatStatus}
 */
proto.event.web_partnership.Seat.prototype.getStatus = function() {
  return /** @type {!proto.event.web_partnership.SeatStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.event.web_partnership.SeatStatus} value
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 order_x = 5;
 * @return {number}
 */
proto.event.web_partnership.Seat.prototype.getOrderX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.setOrderX = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 order_y = 6;
 * @return {number}
 */
proto.event.web_partnership.Seat.prototype.getOrderY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.setOrderY = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional SeatStatusLog log = 7;
 * @return {?proto.event.web_partnership.SeatStatusLog}
 */
proto.event.web_partnership.Seat.prototype.getLog = function() {
  return /** @type{?proto.event.web_partnership.SeatStatusLog} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatStatusLog, 7));
};


/**
 * @param {?proto.event.web_partnership.SeatStatusLog|undefined} value
 * @return {!proto.event.web_partnership.Seat} returns this
*/
proto.event.web_partnership.Seat.prototype.setLog = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.clearLog = function() {
  return this.setLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.Seat.prototype.hasLog = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_paper_ticket = 8;
 * @return {boolean}
 */
proto.event.web_partnership.Seat.prototype.getIsPaperTicket = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.setIsPaperTicket = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string owner_id = 9;
 * @return {string}
 */
proto.event.web_partnership.Seat.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.Seat} returns this
 */
proto.event.web_partnership.Seat.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.SeatStatusLog.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.SeatStatusLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.SeatStatusLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatStatusLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oldStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    note: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.SeatStatusLog}
 */
proto.event.web_partnership.SeatStatusLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.SeatStatusLog;
  return proto.event.web_partnership.SeatStatusLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.SeatStatusLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.SeatStatusLog}
 */
proto.event.web_partnership.SeatStatusLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 3:
      var value = /** @type {!proto.event.web_partnership.SeatStatus} */ (reader.readEnum());
      msg.setOldStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.event.web_partnership.SeatStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.SeatStatusLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.SeatStatusLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.SeatStatusLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.SeatStatusLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOldStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 updated_at = 1;
 * @return {number}
 */
proto.event.web_partnership.SeatStatusLog.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.event.web_partnership.SeatStatusLog} returns this
 */
proto.event.web_partnership.SeatStatusLog.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string updated_by = 2;
 * @return {string}
 */
proto.event.web_partnership.SeatStatusLog.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatStatusLog} returns this
 */
proto.event.web_partnership.SeatStatusLog.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SeatStatus old_status = 3;
 * @return {!proto.event.web_partnership.SeatStatus}
 */
proto.event.web_partnership.SeatStatusLog.prototype.getOldStatus = function() {
  return /** @type {!proto.event.web_partnership.SeatStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.event.web_partnership.SeatStatus} value
 * @return {!proto.event.web_partnership.SeatStatusLog} returns this
 */
proto.event.web_partnership.SeatStatusLog.prototype.setOldStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional SeatStatus status = 4;
 * @return {!proto.event.web_partnership.SeatStatus}
 */
proto.event.web_partnership.SeatStatusLog.prototype.getStatus = function() {
  return /** @type {!proto.event.web_partnership.SeatStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.event.web_partnership.SeatStatus} value
 * @return {!proto.event.web_partnership.SeatStatusLog} returns this
 */
proto.event.web_partnership.SeatStatusLog.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.event.web_partnership.SeatStatusLog.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.SeatStatusLog} returns this
 */
proto.event.web_partnership.SeatStatusLog.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.CreateSeatMapReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.CreateSeatMapReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.CreateSeatMapReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatMapReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.event.web_partnership.SeatMapInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.CreateSeatMapReq}
 */
proto.event.web_partnership.CreateSeatMapReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.CreateSeatMapReq;
  return proto.event.web_partnership.CreateSeatMapReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.CreateSeatMapReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.CreateSeatMapReq}
 */
proto.event.web_partnership.CreateSeatMapReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.event.web_partnership.SeatMapInfo;
      reader.readMessage(value,proto.event.web_partnership.SeatMapInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.CreateSeatMapReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.CreateSeatMapReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.CreateSeatMapReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatMapReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.event.web_partnership.SeatMapInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional SeatMapInfo info = 1;
 * @return {?proto.event.web_partnership.SeatMapInfo}
 */
proto.event.web_partnership.CreateSeatMapReq.prototype.getInfo = function() {
  return /** @type{?proto.event.web_partnership.SeatMapInfo} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatMapInfo, 1));
};


/**
 * @param {?proto.event.web_partnership.SeatMapInfo|undefined} value
 * @return {!proto.event.web_partnership.CreateSeatMapReq} returns this
*/
proto.event.web_partnership.CreateSeatMapReq.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.CreateSeatMapReq} returns this
 */
proto.event.web_partnership.CreateSeatMapReq.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.CreateSeatMapReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.UpdateSeatMapReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.UpdateSeatMapReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.UpdateSeatMapReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatMapReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    info: (f = msg.getInfo()) && proto.event.web_partnership.SeatMapInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.UpdateSeatMapReq}
 */
proto.event.web_partnership.UpdateSeatMapReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.UpdateSeatMapReq;
  return proto.event.web_partnership.UpdateSeatMapReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.UpdateSeatMapReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.UpdateSeatMapReq}
 */
proto.event.web_partnership.UpdateSeatMapReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.event.web_partnership.SeatMapInfo;
      reader.readMessage(value,proto.event.web_partnership.SeatMapInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.UpdateSeatMapReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.UpdateSeatMapReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.UpdateSeatMapReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatMapReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.event.web_partnership.SeatMapInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.UpdateSeatMapReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.UpdateSeatMapReq} returns this
 */
proto.event.web_partnership.UpdateSeatMapReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SeatMapInfo info = 2;
 * @return {?proto.event.web_partnership.SeatMapInfo}
 */
proto.event.web_partnership.UpdateSeatMapReq.prototype.getInfo = function() {
  return /** @type{?proto.event.web_partnership.SeatMapInfo} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatMapInfo, 2));
};


/**
 * @param {?proto.event.web_partnership.SeatMapInfo|undefined} value
 * @return {!proto.event.web_partnership.UpdateSeatMapReq} returns this
*/
proto.event.web_partnership.UpdateSeatMapReq.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.UpdateSeatMapReq} returns this
 */
proto.event.web_partnership.UpdateSeatMapReq.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.UpdateSeatMapReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.DeleteSeatMapReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.DeleteSeatMapReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.DeleteSeatMapReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.DeleteSeatMapReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.DeleteSeatMapReq}
 */
proto.event.web_partnership.DeleteSeatMapReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.DeleteSeatMapReq;
  return proto.event.web_partnership.DeleteSeatMapReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.DeleteSeatMapReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.DeleteSeatMapReq}
 */
proto.event.web_partnership.DeleteSeatMapReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.DeleteSeatMapReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.DeleteSeatMapReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.DeleteSeatMapReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.DeleteSeatMapReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.DeleteSeatMapReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.DeleteSeatMapReq} returns this
 */
proto.event.web_partnership.DeleteSeatMapReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.ListSeatMapReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.ListSeatMapReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.ListSeatMapReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatMapReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventAssocStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    orderByList: jspb.Message.toObjectList(msg.getOrderByList(),
    base_base_pb.OrderByItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.ListSeatMapReq}
 */
proto.event.web_partnership.ListSeatMapReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.ListSeatMapReq;
  return proto.event.web_partnership.ListSeatMapReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.ListSeatMapReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.ListSeatMapReq}
 */
proto.event.web_partnership.ListSeatMapReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    case 2:
      var value = /** @type {!proto.event.web_partnership.EventAssocStatus} */ (reader.readEnum());
      msg.setEventAssocStatus(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new base_base_pb.OrderByItem;
      reader.readMessage(value,base_base_pb.OrderByItem.deserializeBinaryFromReader);
      msg.addOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.ListSeatMapReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.ListSeatMapReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatMapReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventAssocStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      base_base_pb.OrderByItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string search_text = 1;
 * @return {string}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.ListSeatMapReq} returns this
 */
proto.event.web_partnership.ListSeatMapReq.prototype.setSearchText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EventAssocStatus event_assoc_status = 2;
 * @return {!proto.event.web_partnership.EventAssocStatus}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.getEventAssocStatus = function() {
  return /** @type {!proto.event.web_partnership.EventAssocStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.event.web_partnership.EventAssocStatus} value
 * @return {!proto.event.web_partnership.ListSeatMapReq} returns this
 */
proto.event.web_partnership.ListSeatMapReq.prototype.setEventAssocStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional base.PaginationReq pagination = 3;
 * @return {?proto.base.PaginationReq}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 3));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.event.web_partnership.ListSeatMapReq} returns this
*/
proto.event.web_partnership.ListSeatMapReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.ListSeatMapReq} returns this
 */
proto.event.web_partnership.ListSeatMapReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated base.OrderByItem order_by = 4;
 * @return {!Array<!proto.base.OrderByItem>}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.getOrderByList = function() {
  return /** @type{!Array<!proto.base.OrderByItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, base_base_pb.OrderByItem, 4));
};


/**
 * @param {!Array<!proto.base.OrderByItem>} value
 * @return {!proto.event.web_partnership.ListSeatMapReq} returns this
*/
proto.event.web_partnership.ListSeatMapReq.prototype.setOrderByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.base.OrderByItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.base.OrderByItem}
 */
proto.event.web_partnership.ListSeatMapReq.prototype.addOrderBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.base.OrderByItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.ListSeatMapReq} returns this
 */
proto.event.web_partnership.ListSeatMapReq.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.RetrieveSeatMapReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.RetrieveSeatMapReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.RetrieveSeatMapReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatMapReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.RetrieveSeatMapReq}
 */
proto.event.web_partnership.RetrieveSeatMapReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.RetrieveSeatMapReq;
  return proto.event.web_partnership.RetrieveSeatMapReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.RetrieveSeatMapReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.RetrieveSeatMapReq}
 */
proto.event.web_partnership.RetrieveSeatMapReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.RetrieveSeatMapReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.RetrieveSeatMapReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.RetrieveSeatMapReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatMapReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.RetrieveSeatMapReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.RetrieveSeatMapReq} returns this
 */
proto.event.web_partnership.RetrieveSeatMapReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.CreateSeatBlockReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.CreateSeatBlockReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.CreateSeatBlockReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatBlockReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatBlock: (f = msg.getSeatBlock()) && proto.event.web_partnership.SeatBlockInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.CreateSeatBlockReq}
 */
proto.event.web_partnership.CreateSeatBlockReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.CreateSeatBlockReq;
  return proto.event.web_partnership.CreateSeatBlockReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.CreateSeatBlockReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.CreateSeatBlockReq}
 */
proto.event.web_partnership.CreateSeatBlockReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = new proto.event.web_partnership.SeatBlockInfo;
      reader.readMessage(value,proto.event.web_partnership.SeatBlockInfo.deserializeBinaryFromReader);
      msg.setSeatBlock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.CreateSeatBlockReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.CreateSeatBlockReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.CreateSeatBlockReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatBlockReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatBlock();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.event.web_partnership.SeatBlockInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.CreateSeatBlockReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.CreateSeatBlockReq} returns this
 */
proto.event.web_partnership.CreateSeatBlockReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SeatBlockInfo seat_block = 2;
 * @return {?proto.event.web_partnership.SeatBlockInfo}
 */
proto.event.web_partnership.CreateSeatBlockReq.prototype.getSeatBlock = function() {
  return /** @type{?proto.event.web_partnership.SeatBlockInfo} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatBlockInfo, 2));
};


/**
 * @param {?proto.event.web_partnership.SeatBlockInfo|undefined} value
 * @return {!proto.event.web_partnership.CreateSeatBlockReq} returns this
*/
proto.event.web_partnership.CreateSeatBlockReq.prototype.setSeatBlock = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.CreateSeatBlockReq} returns this
 */
proto.event.web_partnership.CreateSeatBlockReq.prototype.clearSeatBlock = function() {
  return this.setSeatBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.CreateSeatBlockReq.prototype.hasSeatBlock = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.ListSeatBlockReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.ListSeatBlockReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.ListSeatBlockReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatBlockReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatDetail: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.ListSeatBlockReq}
 */
proto.event.web_partnership.ListSeatBlockReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.ListSeatBlockReq;
  return proto.event.web_partnership.ListSeatBlockReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.ListSeatBlockReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.ListSeatBlockReq}
 */
proto.event.web_partnership.ListSeatBlockReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeatDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.ListSeatBlockReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.ListSeatBlockReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.ListSeatBlockReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatBlockReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatDetail();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.ListSeatBlockReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.ListSeatBlockReq} returns this
 */
proto.event.web_partnership.ListSeatBlockReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool seat_detail = 2;
 * @return {boolean}
 */
proto.event.web_partnership.ListSeatBlockReq.prototype.getSeatDetail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.event.web_partnership.ListSeatBlockReq} returns this
 */
proto.event.web_partnership.ListSeatBlockReq.prototype.setSeatDetail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.UpdateSeatBlockReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.UpdateSeatBlockReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatBlockReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatBlockId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    info: (f = msg.getInfo()) && proto.event.web_partnership.SeatBlockInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.UpdateSeatBlockReq}
 */
proto.event.web_partnership.UpdateSeatBlockReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.UpdateSeatBlockReq;
  return proto.event.web_partnership.UpdateSeatBlockReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.UpdateSeatBlockReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.UpdateSeatBlockReq}
 */
proto.event.web_partnership.UpdateSeatBlockReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatBlockId(value);
      break;
    case 3:
      var value = new proto.event.web_partnership.SeatBlockInfo;
      reader.readMessage(value,proto.event.web_partnership.SeatBlockInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.UpdateSeatBlockReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.UpdateSeatBlockReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatBlockReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatBlockId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.event.web_partnership.SeatBlockInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.UpdateSeatBlockReq} returns this
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string seat_block_id = 2;
 * @return {string}
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.getSeatBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.UpdateSeatBlockReq} returns this
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.setSeatBlockId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SeatBlockInfo info = 3;
 * @return {?proto.event.web_partnership.SeatBlockInfo}
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.getInfo = function() {
  return /** @type{?proto.event.web_partnership.SeatBlockInfo} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatBlockInfo, 3));
};


/**
 * @param {?proto.event.web_partnership.SeatBlockInfo|undefined} value
 * @return {!proto.event.web_partnership.UpdateSeatBlockReq} returns this
*/
proto.event.web_partnership.UpdateSeatBlockReq.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.UpdateSeatBlockReq} returns this
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.UpdateSeatBlockReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.DeleteSeatBlockReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.DeleteSeatBlockReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.DeleteSeatBlockReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.DeleteSeatBlockReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatBlockId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.DeleteSeatBlockReq}
 */
proto.event.web_partnership.DeleteSeatBlockReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.DeleteSeatBlockReq;
  return proto.event.web_partnership.DeleteSeatBlockReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.DeleteSeatBlockReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.DeleteSeatBlockReq}
 */
proto.event.web_partnership.DeleteSeatBlockReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatBlockId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.DeleteSeatBlockReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.DeleteSeatBlockReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.DeleteSeatBlockReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.DeleteSeatBlockReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatBlockId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.DeleteSeatBlockReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.DeleteSeatBlockReq} returns this
 */
proto.event.web_partnership.DeleteSeatBlockReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string seat_block_id = 2;
 * @return {string}
 */
proto.event.web_partnership.DeleteSeatBlockReq.prototype.getSeatBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.DeleteSeatBlockReq} returns this
 */
proto.event.web_partnership.DeleteSeatBlockReq.prototype.setSeatBlockId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.RetrieveSeatBlockReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.RetrieveSeatBlockReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.RetrieveSeatBlockReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatBlockReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatBlockId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.RetrieveSeatBlockReq}
 */
proto.event.web_partnership.RetrieveSeatBlockReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.RetrieveSeatBlockReq;
  return proto.event.web_partnership.RetrieveSeatBlockReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.RetrieveSeatBlockReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.RetrieveSeatBlockReq}
 */
proto.event.web_partnership.RetrieveSeatBlockReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatBlockId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.RetrieveSeatBlockReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.RetrieveSeatBlockReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.RetrieveSeatBlockReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatBlockReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatBlockId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.RetrieveSeatBlockReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.RetrieveSeatBlockReq} returns this
 */
proto.event.web_partnership.RetrieveSeatBlockReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string seat_block_id = 2;
 * @return {string}
 */
proto.event.web_partnership.RetrieveSeatBlockReq.prototype.getSeatBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.RetrieveSeatBlockReq} returns this
 */
proto.event.web_partnership.RetrieveSeatBlockReq.prototype.setSeatBlockId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.UpsertDecorationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.UpsertDecorationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.UpsertDecorationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpsertDecorationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    info: (f = msg.getInfo()) && proto.event.web_partnership.Sharp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.UpsertDecorationReq}
 */
proto.event.web_partnership.UpsertDecorationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.UpsertDecorationReq;
  return proto.event.web_partnership.UpsertDecorationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.UpsertDecorationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.UpsertDecorationReq}
 */
proto.event.web_partnership.UpsertDecorationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = new proto.event.web_partnership.Sharp;
      reader.readMessage(value,proto.event.web_partnership.Sharp.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.UpsertDecorationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.UpsertDecorationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.UpsertDecorationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpsertDecorationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.event.web_partnership.Sharp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.UpsertDecorationReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.UpsertDecorationReq} returns this
 */
proto.event.web_partnership.UpsertDecorationReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Sharp info = 2;
 * @return {?proto.event.web_partnership.Sharp}
 */
proto.event.web_partnership.UpsertDecorationReq.prototype.getInfo = function() {
  return /** @type{?proto.event.web_partnership.Sharp} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.Sharp, 2));
};


/**
 * @param {?proto.event.web_partnership.Sharp|undefined} value
 * @return {!proto.event.web_partnership.UpsertDecorationReq} returns this
*/
proto.event.web_partnership.UpsertDecorationReq.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.UpsertDecorationReq} returns this
 */
proto.event.web_partnership.UpsertDecorationReq.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.UpsertDecorationReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.UpdateSeatStatusReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.UpdateSeatStatusReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.UpdateSeatStatusReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatStatusReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatDetailList: jspb.Message.toObjectList(msg.getSeatDetailList(),
    proto.event.web_partnership.UpdateSeatStatusDetailReq.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isPaperTicket: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    note: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq}
 */
proto.event.web_partnership.UpdateSeatStatusReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.UpdateSeatStatusReq;
  return proto.event.web_partnership.UpdateSeatStatusReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.UpdateSeatStatusReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq}
 */
proto.event.web_partnership.UpdateSeatStatusReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = new proto.event.web_partnership.UpdateSeatStatusDetailReq;
      reader.readMessage(value,proto.event.web_partnership.UpdateSeatStatusDetailReq.deserializeBinaryFromReader);
      msg.addSeatDetail(value);
      break;
    case 3:
      var value = /** @type {!proto.event.web_partnership.SeatStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPaperTicket(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.UpdateSeatStatusReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.UpdateSeatStatusReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatStatusReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.event.web_partnership.UpdateSeatStatusDetailReq.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsPaperTicket();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UpdateSeatStatusDetailReq seat_detail = 2;
 * @return {!Array<!proto.event.web_partnership.UpdateSeatStatusDetailReq>}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.getSeatDetailList = function() {
  return /** @type{!Array<!proto.event.web_partnership.UpdateSeatStatusDetailReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.UpdateSeatStatusDetailReq, 2));
};


/**
 * @param {!Array<!proto.event.web_partnership.UpdateSeatStatusDetailReq>} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq} returns this
*/
proto.event.web_partnership.UpdateSeatStatusReq.prototype.setSeatDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.event.web_partnership.UpdateSeatStatusDetailReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.UpdateSeatStatusDetailReq}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.addSeatDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.event.web_partnership.UpdateSeatStatusDetailReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.clearSeatDetailList = function() {
  return this.setSeatDetailList([]);
};


/**
 * optional SeatStatus status = 3;
 * @return {!proto.event.web_partnership.SeatStatus}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.getStatus = function() {
  return /** @type {!proto.event.web_partnership.SeatStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.event.web_partnership.SeatStatus} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_paper_ticket = 4;
 * @return {boolean}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.getIsPaperTicket = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.setIsPaperTicket = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusReq.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.UpdateSeatStatusDetailReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.UpdateSeatStatusDetailReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatBlockId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.UpdateSeatStatusDetailReq}
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.UpdateSeatStatusDetailReq;
  return proto.event.web_partnership.UpdateSeatStatusDetailReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.UpdateSeatStatusDetailReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.UpdateSeatStatusDetailReq}
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatBlockId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeatIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.UpdateSeatStatusDetailReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.UpdateSeatStatusDetailReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatBlockId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string seat_block_id = 1;
 * @return {string}
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.getSeatBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusDetailReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.setSeatBlockId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string seat_ids = 2;
 * @return {!Array<string>}
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.getSeatIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusDetailReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.setSeatIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.UpdateSeatStatusDetailReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.addSeatIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.UpdateSeatStatusDetailReq} returns this
 */
proto.event.web_partnership.UpdateSeatStatusDetailReq.prototype.clearSeatIdsList = function() {
  return this.setSeatIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.ListDecorationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.ListDecorationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.ListDecorationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListDecorationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.ListDecorationReq}
 */
proto.event.web_partnership.ListDecorationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.ListDecorationReq;
  return proto.event.web_partnership.ListDecorationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.ListDecorationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.ListDecorationReq}
 */
proto.event.web_partnership.ListDecorationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.ListDecorationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.ListDecorationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.ListDecorationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListDecorationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.ListDecorationReq.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.ListDecorationReq} returns this
 */
proto.event.web_partnership.ListDecorationReq.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.CreateSeatMapRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.CreateSeatMapRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.CreateSeatMapRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatMapRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.CreateSeatMapRes}
 */
proto.event.web_partnership.CreateSeatMapRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.CreateSeatMapRes;
  return proto.event.web_partnership.CreateSeatMapRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.CreateSeatMapRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.CreateSeatMapRes}
 */
proto.event.web_partnership.CreateSeatMapRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.CreateSeatMapRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.CreateSeatMapRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.CreateSeatMapRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatMapRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.event.web_partnership.CreateSeatMapRes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.CreateSeatMapRes} returns this
 */
proto.event.web_partnership.CreateSeatMapRes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.RetrieveSeatMapRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.RetrieveSeatMapRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.RetrieveSeatMapRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatMapRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.event.web_partnership.SeatMap.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.RetrieveSeatMapRes}
 */
proto.event.web_partnership.RetrieveSeatMapRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.RetrieveSeatMapRes;
  return proto.event.web_partnership.RetrieveSeatMapRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.RetrieveSeatMapRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.RetrieveSeatMapRes}
 */
proto.event.web_partnership.RetrieveSeatMapRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.event.web_partnership.SeatMap;
      reader.readMessage(value,proto.event.web_partnership.SeatMap.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.RetrieveSeatMapRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.RetrieveSeatMapRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.RetrieveSeatMapRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatMapRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.event.web_partnership.SeatMap.serializeBinaryToWriter
    );
  }
};


/**
 * optional SeatMap info = 1;
 * @return {?proto.event.web_partnership.SeatMap}
 */
proto.event.web_partnership.RetrieveSeatMapRes.prototype.getInfo = function() {
  return /** @type{?proto.event.web_partnership.SeatMap} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatMap, 1));
};


/**
 * @param {?proto.event.web_partnership.SeatMap|undefined} value
 * @return {!proto.event.web_partnership.RetrieveSeatMapRes} returns this
*/
proto.event.web_partnership.RetrieveSeatMapRes.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.RetrieveSeatMapRes} returns this
 */
proto.event.web_partnership.RetrieveSeatMapRes.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.RetrieveSeatMapRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.ListSeatMapRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.ListSeatMapRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.ListSeatMapRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.ListSeatMapRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatMapRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.event.web_partnership.SeatMap.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.ListSeatMapRes}
 */
proto.event.web_partnership.ListSeatMapRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.ListSeatMapRes;
  return proto.event.web_partnership.ListSeatMapRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.ListSeatMapRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.ListSeatMapRes}
 */
proto.event.web_partnership.ListSeatMapRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.event.web_partnership.SeatMap;
      reader.readMessage(value,proto.event.web_partnership.SeatMap.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.ListSeatMapRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.ListSeatMapRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.ListSeatMapRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatMapRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.event.web_partnership.SeatMap.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SeatMap items = 1;
 * @return {!Array<!proto.event.web_partnership.SeatMap>}
 */
proto.event.web_partnership.ListSeatMapRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.event.web_partnership.SeatMap>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.SeatMap, 1));
};


/**
 * @param {!Array<!proto.event.web_partnership.SeatMap>} value
 * @return {!proto.event.web_partnership.ListSeatMapRes} returns this
*/
proto.event.web_partnership.ListSeatMapRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.event.web_partnership.SeatMap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.SeatMap}
 */
proto.event.web_partnership.ListSeatMapRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.event.web_partnership.SeatMap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.ListSeatMapRes} returns this
 */
proto.event.web_partnership.ListSeatMapRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.event.web_partnership.ListSeatMapRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.event.web_partnership.ListSeatMapRes} returns this
*/
proto.event.web_partnership.ListSeatMapRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.ListSeatMapRes} returns this
 */
proto.event.web_partnership.ListSeatMapRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.ListSeatMapRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.ListSeatBlockRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.ListSeatBlockRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.ListSeatBlockRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.ListSeatBlockRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatBlockRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.event.web_partnership.SeatBlock.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.ListSeatBlockRes}
 */
proto.event.web_partnership.ListSeatBlockRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.ListSeatBlockRes;
  return proto.event.web_partnership.ListSeatBlockRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.ListSeatBlockRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.ListSeatBlockRes}
 */
proto.event.web_partnership.ListSeatBlockRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.event.web_partnership.SeatBlock;
      reader.readMessage(value,proto.event.web_partnership.SeatBlock.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.ListSeatBlockRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.ListSeatBlockRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.ListSeatBlockRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListSeatBlockRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.event.web_partnership.SeatBlock.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SeatBlock items = 1;
 * @return {!Array<!proto.event.web_partnership.SeatBlock>}
 */
proto.event.web_partnership.ListSeatBlockRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.event.web_partnership.SeatBlock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.SeatBlock, 1));
};


/**
 * @param {!Array<!proto.event.web_partnership.SeatBlock>} value
 * @return {!proto.event.web_partnership.ListSeatBlockRes} returns this
*/
proto.event.web_partnership.ListSeatBlockRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.event.web_partnership.SeatBlock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.SeatBlock}
 */
proto.event.web_partnership.ListSeatBlockRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.event.web_partnership.SeatBlock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.ListSeatBlockRes} returns this
 */
proto.event.web_partnership.ListSeatBlockRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.ListDecorationRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.ListDecorationRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.ListDecorationRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.ListDecorationRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListDecorationRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.event.web_partnership.Sharp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.ListDecorationRes}
 */
proto.event.web_partnership.ListDecorationRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.ListDecorationRes;
  return proto.event.web_partnership.ListDecorationRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.ListDecorationRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.ListDecorationRes}
 */
proto.event.web_partnership.ListDecorationRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.event.web_partnership.Sharp;
      reader.readMessage(value,proto.event.web_partnership.Sharp.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.ListDecorationRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.ListDecorationRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.ListDecorationRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.ListDecorationRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.event.web_partnership.Sharp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Sharp items = 1;
 * @return {!Array<!proto.event.web_partnership.Sharp>}
 */
proto.event.web_partnership.ListDecorationRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.event.web_partnership.Sharp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event.web_partnership.Sharp, 1));
};


/**
 * @param {!Array<!proto.event.web_partnership.Sharp>} value
 * @return {!proto.event.web_partnership.ListDecorationRes} returns this
*/
proto.event.web_partnership.ListDecorationRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.event.web_partnership.Sharp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.Sharp}
 */
proto.event.web_partnership.ListDecorationRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.event.web_partnership.Sharp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.ListDecorationRes} returns this
 */
proto.event.web_partnership.ListDecorationRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.CreateSeatBlockRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.CreateSeatBlockRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.CreateSeatBlockRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatBlockRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatMapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seatBlockId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.CreateSeatBlockRes}
 */
proto.event.web_partnership.CreateSeatBlockRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.CreateSeatBlockRes;
  return proto.event.web_partnership.CreateSeatBlockRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.CreateSeatBlockRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.CreateSeatBlockRes}
 */
proto.event.web_partnership.CreateSeatBlockRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatMapId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatBlockId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.CreateSeatBlockRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.CreateSeatBlockRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.CreateSeatBlockRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.CreateSeatBlockRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatMapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeatBlockId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string seat_map_id = 1;
 * @return {string}
 */
proto.event.web_partnership.CreateSeatBlockRes.prototype.getSeatMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.CreateSeatBlockRes} returns this
 */
proto.event.web_partnership.CreateSeatBlockRes.prototype.setSeatMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string seat_block_id = 2;
 * @return {string}
 */
proto.event.web_partnership.CreateSeatBlockRes.prototype.getSeatBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event.web_partnership.CreateSeatBlockRes} returns this
 */
proto.event.web_partnership.CreateSeatBlockRes.prototype.setSeatBlockId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event.web_partnership.UpdateSeatStatusRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.UpdateSeatStatusRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.UpdateSeatStatusRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatStatusRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccessed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorSeatIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.UpdateSeatStatusRes}
 */
proto.event.web_partnership.UpdateSeatStatusRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.UpdateSeatStatusRes;
  return proto.event.web_partnership.UpdateSeatStatusRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.UpdateSeatStatusRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.UpdateSeatStatusRes}
 */
proto.event.web_partnership.UpdateSeatStatusRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccessed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addErrorSeatIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.UpdateSeatStatusRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.UpdateSeatStatusRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.UpdateSeatStatusRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccessed();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorSeatIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional bool is_successed = 1;
 * @return {boolean}
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.getIsSuccessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusRes} returns this
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.setIsSuccessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated string error_seat_ids = 2;
 * @return {!Array<string>}
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.getErrorSeatIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.event.web_partnership.UpdateSeatStatusRes} returns this
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.setErrorSeatIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.event.web_partnership.UpdateSeatStatusRes} returns this
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.addErrorSeatIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event.web_partnership.UpdateSeatStatusRes} returns this
 */
proto.event.web_partnership.UpdateSeatStatusRes.prototype.clearErrorSeatIdsList = function() {
  return this.setErrorSeatIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event.web_partnership.RetrieveSeatBlockRes.prototype.toObject = function(opt_includeInstance) {
  return proto.event.web_partnership.RetrieveSeatBlockRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event.web_partnership.RetrieveSeatBlockRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatBlockRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.event.web_partnership.SeatBlock.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event.web_partnership.RetrieveSeatBlockRes}
 */
proto.event.web_partnership.RetrieveSeatBlockRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event.web_partnership.RetrieveSeatBlockRes;
  return proto.event.web_partnership.RetrieveSeatBlockRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event.web_partnership.RetrieveSeatBlockRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event.web_partnership.RetrieveSeatBlockRes}
 */
proto.event.web_partnership.RetrieveSeatBlockRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.event.web_partnership.SeatBlock;
      reader.readMessage(value,proto.event.web_partnership.SeatBlock.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event.web_partnership.RetrieveSeatBlockRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event.web_partnership.RetrieveSeatBlockRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event.web_partnership.RetrieveSeatBlockRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event.web_partnership.RetrieveSeatBlockRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.event.web_partnership.SeatBlock.serializeBinaryToWriter
    );
  }
};


/**
 * optional SeatBlock info = 1;
 * @return {?proto.event.web_partnership.SeatBlock}
 */
proto.event.web_partnership.RetrieveSeatBlockRes.prototype.getInfo = function() {
  return /** @type{?proto.event.web_partnership.SeatBlock} */ (
    jspb.Message.getWrapperField(this, proto.event.web_partnership.SeatBlock, 1));
};


/**
 * @param {?proto.event.web_partnership.SeatBlock|undefined} value
 * @return {!proto.event.web_partnership.RetrieveSeatBlockRes} returns this
*/
proto.event.web_partnership.RetrieveSeatBlockRes.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event.web_partnership.RetrieveSeatBlockRes} returns this
 */
proto.event.web_partnership.RetrieveSeatBlockRes.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event.web_partnership.RetrieveSeatBlockRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.event.web_partnership.SeatStatus = {
  SEAT_STATUS_UNUSE: 0,
  SEAT_STATUS_AVAILABLE: 1,
  SEAT_STATUS_BOOKING: 2,
  SEAT_STATUS_LOCKED: 3,
  SEAT_STATUS_SOLD: 4,
  SEAT_STATUS_DISABLE: 99
};

/**
 * @enum {number}
 */
proto.event.web_partnership.EventAssocStatus = {
  EVENT_ASSOC_UNUSE: 0,
  EVENT_ASSOC_NOT_YET: 1,
  EVENT_ASSOC_LOCKED: 2
};

goog.object.extend(exports, proto.event.web_partnership);
