export const customToken = {
  imageSizeXS: 64,
  imageSizeSM: 128,
  imageSize: 192,
  imageSizeMD: 200,
  imageSizeLG: 320,
  imageSizeXl: 416,
  imageSize2XL: 512,
  imageSize3XL: 768,
  imageSize4XL: 896,
};
