export const seedToken = {
  borderRadius: 6,

  colorBgBase: '#fff',
  colorError: '#ff4d4f',
  colorInfo: '#1677ff',
  colorPrimary: '#1677ff',
  colorSuccess: '#52c41a',
  colorTextBase: '#000',
  colorWarning: '#faad14',

  controlHeight: 32,
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  fontSize: 16,
  lineType: 'solid',
  lineWidth: 1,
  motionUnit: 0.1,
  sizeStep: 4,
  sizeUnit: 4,
  wireframe: false,
  zIndexBase: 0,
  zIndexPopupBase: 1000,
};
