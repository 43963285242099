export const aliasToken = {
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)',
  boxShadowSecondary:
    '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',

  colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
  colorBgTextActive: 'rgba(0, 0, 0, 0.15)',
  colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
  colorBorderBg: '#ffffff',
  colorErrorOutline: 'rgba(255, 38, 5, 0.06)',
  colorFillAlter: 'rgba(0, 0, 0, 0.02)',
  colorFillContent: 'rgba(0, 0, 0, 0.06)',
  colorFillContentHover: 'rgba(0, 0, 0, 0.15)',
  colorHighlight: '#ff4d4f',
  colorIcon: 'rgba(0, 0, 0, 0.45)',
  colorIconHover: 'rgba(0, 0, 0, 0.88)',
  colorLink: '#1677ff',
  colorLinkActive: '#0958d9',
  colorLinkHover: '#69b1ff',
  colorSplit: 'rgba(5, 5, 5, 0.06)',
  colorTextDescription: 'rgba(0, 0, 0, 0.45)',
  colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
  colorTextHeading: 'rgba(0, 0, 0, 0.88)',
  colorTextLabel: 'rgba(0, 0, 0, 0.65)',
  colorTextLightSolid: '#fff',
  colorTextPlaceholder: 'colorTextPlaceholder',
  colorWarningOutline: 'rgba(255, 215, 5, 0.1)',

  controlInteractiveSize: 16,
  controlItemBgActive: '#e6f4ff',
  controlItemBgActiveDisabled: 'rgba(0, 0, 0, 0.15)',
  controlItemBgActiveHover: '#bae0ff',
  controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
  controlOutline: 'rgba(5, 145, 255, 0.1)',
  controlOutlineWidth: 2,
  controlPaddingHorizontal: 12,
  controlPaddingHorizontalSM: 8,
  controlTmpOutline: 'rgba(0, 0, 0, 0.02)',

  fontSizeIcon: 12,
  fontWeightStrong: 600,

  linkDecoration: 'none',
  linkFocusDecoration: 'none',
  linkHoverDecoration: 'none',

  margin: 16,
  marginLG: 24,
  marginMD: 20,
  marginSM: 12,
  marginXL: 32,
  marginXS: 8,
  marginXXL: 48,
  marginXXS: 4,

  opacityLoading: 0.65,

  padding: 16,
  paddingContentHorizontal: 16,
  paddingContentHorizontalLG: 24,
  paddingContentHorizontalSM: 16,
  paddingContentVertical: 12,
  paddingContentVerticalLG: 16,
  paddingContentVerticalSM: 8,
  paddingLG: 24,
  paddingMD: 20,
  paddingSM: 12,
  paddingXL: 32,
  paddingXS: 8,
  paddingXXS: 4,

  screenLG: 992,
  screenLGMax: 1199,
  screenLGMin: 992,
  screenMD: 768,
  screenMDMax: 991,
  screenMDMin: 768,
  screenSM: 576,
  screenSMMax: 767,
  screenSMMin: 576,
  screenXL: 1200,
  screenXLMax: 1599,
  screenXLMin: 1200,
  screenXS: 480,
  screenXSMax: 575,
  screenXSMin: 480,
  screenXXL: 1600,
  screenXXLMin: 1600,
};
