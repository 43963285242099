export enum ESize {
  NONE = 'none',
  XXS = 'XXS',
  XS = 'XS',
  SM = 'SM',
  N = '',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
  XXL = 'XXL',
}
